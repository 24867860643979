import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import SEO from "../../../components/seo"
import * as D3 from "d3"
import useContainerDimensions from "../../../components/useContainerDimensions"
import colorLegend from "../../../components/colorLegend"
import lineChart from "../../../components/lineChart"
import styles from "./animals-slaughtered.module.css"
import "../../general.css"
import {
  chartTitleTextStyle,
  vizBackgroundStyle
} from "../../../defines";

const Title = "Animals slaughtered for meat";
const MinYear = 1961;
const MaxYear = 2018;

export const query = graphql`
  query {
    allAnimalsSlaughteredCsv {
      nodes {
        Country : Entity
        Code
        Year
        Cattle
        Chickens
        Goats
        Pigs
        Sheep
        Turkeys
      }
    }
  }
`;

const getAnimalList = () => {
  return [
    {
      key: "Cattle",
      value: "Cattle",
      text: "Cattle"
    },
    {
      key: "Chickens",
      value: "Chickens",
      text: "Chickens"
    },
    {
      key: "Goats",
      value: "Goats",
      text: "Goats"
    },
    {
      key: "Pigs",
      value: "Pigs",
      text: "Pigs"
    },
    {
      key: "Sheep",
      value: "Sheep",
      text: "Sheep"
    },
    {
      key: "Turkeys",
      value: "Turkeys",
      text: "Turkeys"
    }
  ];
};

const getYearList = () => {
  return D3.range(MinYear, MaxYear + 1).map(n => ({
    key: n,
    value: n,
    text: n
  }))
}

const getCountries = (data) => {
  let result = [{ key: "World", text: "World", value: "World" }];
  data.allAnimalsSlaughteredCsv.nodes.forEach(element => {
    if (element.Code === "") {
      return;
    }
    if (result.findIndex(c => (c.key === element.Country)) !== -1) {
      return;
    }
    result.push({
      key: element.Country,
      value: element.Country,
      text: element.Country,
      flag: (element.Country === "World") ? undefined :
        element.Country.toLowerCase().replace("'", "")
    });
  });
  return result;
};

const filterData = (data, selectedCountries, selectedAnimal) => {
  if (selectedCountries.length === 0) {
    return [];
  }
  const result = selectedCountries.map(
    (country) => {
      let countryData = {
        country: country,
        values:
          data.allAnimalsSlaughteredCsv.nodes
            .filter(datapoint => datapoint.Country === country)
            .map(datapoint => {
              let p = {
                Year: +datapoint.Year
              };
              if (selectedAnimal === "Cattle") { p.value = +datapoint.Cattle };
              if (selectedAnimal === "Chickens") { p.value = +datapoint.Chickens };
              if (selectedAnimal === "Goats") { p.value = +datapoint.Goats };
              if (selectedAnimal === "Pigs") { p.value = +datapoint.Pigs };
              if (selectedAnimal === "Sheep") { p.value = +datapoint.Sheep };
              if (selectedAnimal === "Turkeys") { p.value = +datapoint.Turkeys };
              return p;
            })
      }
      return countryData;
    });
  return result;
};
const AnimalsSlaughtered = ({ data, mobile }) => {
  let MaxNumSelectedCountries = 7;
  let GraphAspectRatio = 4 / 3;
  if (mobile) {
    GraphAspectRatio = 3 / 4;
    MaxNumSelectedCountries = 4;
  }

  // Country related
  const countryList = getCountries(data);
  const [selectedCountries, setSelectedCountries] = useState(["World", "United States"]);
  const [selectedAnimal, setSelectedAnimal] = useState("Cattle");
  const [selectedYear, setSelectedYear] = useState(2015);
  const selectedYearInt = Math.round(selectedYear);
  const onSelectedCountriesChanged = (event, result) => {
    let { value } = result || event.target;
    if (value.length === MaxNumSelectedCountries + 1) {
      value.shift();
    }
    setSelectedCountries(value);
  };

  // Animal related
  const animalList = getAnimalList();
  const onSelectedAnimalChanged = (event, result) => {
    const { value } = result || event.target;
    setSelectedAnimal(value);
  }

  // Data table related
  const onYearDropdownChanged = (event, result) => {
    let { value } = result || event.target;
    setSelectedYear(value);
  };

  const getDataTableValue = (country, asString = true) => {
    const values = filtered.filter(d => (d.country === country))[0].values;
    const matches = values.filter(d => (d.Year === selectedYearInt));
    if (matches.length === 0) { return asString ? "<no data>" : -1 };
    return asString ? matches[0].value.toLocaleString() : matches[0].value;
  }

  // Graph related
  const filtered = filterData(data, selectedCountries, selectedAnimal);

  const graphDivRef = useRef();
  const { width } = useContainerDimensions(graphDivRef);
  const svgWidth = Math.max(width, 0);
  const svgHeight = Math.max(width / GraphAspectRatio, 0);
  const margin = {
    top: 15,
    right: mobile ? 30 : 50,
    bottom: 45,
    left: mobile ? 30 : 45
  }

  const renderSvg = () => {
    if (typeof document === "undefined") {
      return;
    }
    const svg = D3.select("svg");

    let lineChartG = svg.select(".lineChartG");
    if (lineChartG.empty()) {
      lineChartG = svg.append("g").attr("class", "lineChartG");
    }
    let colorLegendG = svg.select(".colorLegendG");
    if (colorLegendG.empty()) {
      colorLegendG = svg.append("g").attr("class", "colorLegendG");
    }

    const colorValue = d => d.country;
    const colorScale = D3.scaleOrdinal(D3.schemeCategory10)
      .domain(filtered.map(d => colorValue(d)));

    lineChartG.call(lineChart, {
      colorScale,
      yValue: d => d.value,
      xValue: d => d.Year,
      colorScaleKey: d => d.country,
      margin,
      width,
      height: svgHeight,
      data: filtered,
      selectedYear,
      setSelectedYear,
      lineClass: styles.linePath,
      currentYearLineClass: styles.selectedYearLine,
      mobile
    });

    colorLegendG
      .attr("transform", `translate(10,${svgHeight - 10})`)
      .call(colorLegend, {
        colorScale,
        circleRadius: 10,
        spacing: 79,
        textOffset: 12,
        textClass: styles.legendText
      });
  };

  /*********************************
   *    Do the actual rendering    *
   *********************************/
  renderSvg();
  selectedCountries.sort((a, b) => getDataTableValue(b, false) - getDataTableValue(a, false));
  return (
    <>
      <SEO title={Title} />
      <div className="contentDiv">
        <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
        <div>
          <div className={`Rtable Rtable2cols`}>
            <div className={`RtableCellRight ${styles.dropDown}`} >
              <Dropdown
                compact
                basic
                selection
                options={animalList}
                defaultValue={selectedAnimal}
                onChange={onSelectedAnimalChanged}
              />
            </div>
            <div className={`${styles.dropDown}`}>
              <Dropdown
                placeholder="Year"
                selection
                options={getYearList()}
                value={selectedYearInt}
                compact
                basic
                onChange={onYearDropdownChanged}
              />
            </div>
          </div>
          <div className={`RtableCellMiddle ${styles.dropDown}`} style={{ textAlign: "center", paddingTop: 10 }}>
            <Dropdown
              placeholder={`Select up to ${MaxNumSelectedCountries} countries`}
              compact
              multiple
              selection
              search
              options={countryList}
              defaultValue={selectedCountries}
              onChange={onSelectedCountriesChanged}
            />
          </div>
        </div>
        <div className="Rtable Rtable2cols" style={{ "padding": 10 }}>
          {
            selectedCountries.map(sc => {
              return (
                <React.Fragment key={sc}>
                  <div className={`RtableCellRight ${styles.grey}`}>{sc}</div>
                  <div className={`RtableCellLeft ${styles.white}`}>{getDataTableValue(sc)}</div>
                </React.Fragment>
              )
            })
          }
        </div>
        <div className={`vizBackground`} ref={graphDivRef} style={vizBackgroundStyle(mobile)}>
          <div className={`${styles.graphColumn}`}>
            <svg width={`${svgWidth}px`} height={`${svgHeight}px`} />
          </div>
          <div className="citation">
            Data source: <a href="https://ourworldindata.org/grapher/animals-slaughtered-for-meat">Our World in Data</a>
          </div>
        </div>
      </div>
    </>
  )
};

export default AnimalsSlaughtered;
