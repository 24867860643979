const abbreviateString = (s, maxChars) => {
    if (!s) { return ""; }
    if (s.length <= maxChars) {
        return s;
    }
    return `${s.substr(0, maxChars)}...`;
}

export default (selection, props) => {
    const {
        colorScale,
        circleRadius,
        spacing,
        textOffset,
        textClass,
        maxChars = 10
    } = props;

    const groups = selection.selectAll("g")
        .data(colorScale.domain());
    const groupsEnter = groups
        .enter().append("g")
        .attr("class", "color-legend");
    groupsEnter
        .merge(groups)
        .attr("transform", (d, i) => `translate(${i * spacing}, 0)`);
    groups.exit().remove();

    groupsEnter.append("circle")
        .merge(groups.select("circle"))
        .attr("r", circleRadius)
        .attr("fill", colorScale);

    groupsEnter.append("text")
        .merge(groups.select("text"))
        .text(d => abbreviateString(d, maxChars))
        .attr("dy", "0.32em")
        .attr("x", textOffset)
        .attr("class", textClass);
}