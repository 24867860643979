import * as D3 from "d3"

export default (selection, props) => {
    const {
        colorScale,
        colorScaleKey,
        yValue,
        xValue,
        margin,
        width,
        height,
        data,
        selectedYear,
        setSelectedYear,
        lineClass,
        currentYearLineClass,
        isPortrait
    } = props;

    if (width === 0 || height === 0) {
        return;
    }

    const t = selection.transition()
        .duration(750);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xMin = Math.min(...data.map(d => D3.min(d.values, xValue)));
    const xMax = Math.max(...data.map(d => D3.max(d.values, xValue)));
    const valueMax = Math.max(...data.map(d => D3.max(d.values, yValue)));

    const xScale = D3.scaleLinear()
        .domain([xMin, xMax])
        .range([0, innerWidth])
        .nice();

    const yScale = D3.scaleLinear()
        .domain([valueMax, 0])
        .range([0, innerHeight])
        .nice();

    const g = selection.selectAll(".container").data([null]);

    const gEnter = g.enter()
        .append("g")
        .attr("class", "container")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxis = D3.axisBottom(xScale)
        .tickFormat(D3.format(""))
        .ticks(isPortrait ? 3 : 10);

    const yAxis = D3.axisLeft(yScale)
        .tickFormat(function (n) { const fn = D3.format(".2s"); return fn(n).replace(/G/, "B"); })
        .tickSize(-innerWidth);

    gEnter
        .append("g")
        .attr("class", "y-axis")
        .merge(g.select(".y-axis"))
        .call(yAxis);

    gEnter
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${innerHeight})`)
        .merge(g.select(".x-axis"))
        .call(xAxis);

    const lineGenerator = D3.line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))
        .curve(D3.curveBasis);

    g.merge(gEnter)
        .selectAll(`.${lineClass}`)
        .data(data, colorScaleKey)
        .join(
            enter => enter.append("path")
                .attr("class", lineClass)
                .attr("d", d => lineGenerator(d.values))
                .attr("stroke", d => colorScale(colorScaleKey(d))),
            update => update
                .call(update => update.transition(t)
                    .attr("stroke", d => colorScale(colorScaleKey(d)))
                    .attr("d", d => lineGenerator(d.values)))
        );

    gEnter
        .append("line")
        .attr("class", currentYearLineClass)
        .attr("y1", 0)
        .attr("y2", innerHeight)
        .merge(g.select(`.${currentYearLineClass}`))
        .attr("x1", isNaN(xScale(selectedYear)) ? 0 : xScale(selectedYear))
        .attr("x2", isNaN(xScale(selectedYear)) ? 0 : xScale(selectedYear))

    // Can't get the performance of this to work
    gEnter
        .append("rect")
        .attr("class", "mouse-interceptor")
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .merge(g.select(".mouse-interceptor"))
        .attr("width", innerWidth)
        .attr("height", innerHeight)
        .on("mousemove", function () {
            const x = D3.mouse(this)[0];
            const hoveredYear = xScale.invert(x);
            setSelectedYear(hoveredYear);
        });
}
